import React, { createContext, useContext, useState, ReactNode } from 'react';
import AlertSnackbar from './AlertSnackbar';

interface SnackbarContextType {
  snackbarState: { open: boolean; message: string };
  showSnackbar: (message: string) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
  });

  const showSnackbar = (message: string) => {
    setSnackbarState({ open: true, message });
  };

  const hideSnackbar = () => {
    setSnackbarState((state) => ({ ...state, open: false }));
  };

  return (
    <SnackbarContext.Provider
      value={{ snackbarState, showSnackbar, hideSnackbar }}
    >
      {children}
      <AlertSnackbar
        open={snackbarState.open}
        message={snackbarState.message}
        onClose={hideSnackbar}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider.');
  }
  return context;
};
