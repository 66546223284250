import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { appConfig } from '../appConfig';
import { BrandKey } from '../types/results/ICustomerDetailsResult';
import { useSectionContext } from '../SectionContext';

interface BrandSelectProps {
  selectedBrand: BrandKey;
  setSelectedBrand: (brand: BrandKey) => void;
}

export const BrandSelect: React.FC<BrandSelectProps> = ({
  selectedBrand,
  setSelectedBrand,
}) => {
  const { section } = useSectionContext();

  const filteredBrands =
    section === 'OrderSearch' ? appConfig.orderSearchBrands : appConfig.brands;

  return (
    <Select
      sx={{
        height: '2.5rem',
        marginLeft: '-0.95rem',
        '& .MuiOutlinedInput-notchedOutline': {
          borderLeft: 'none',
          borderBottom: 'none',
          borderTop: 'none',
        },
      }}
      value={selectedBrand}
      onChange={(e) => setSelectedBrand(e.target.value as BrandKey)}
    >
      {filteredBrands.map((brand) => (
        <MenuItem key={brand.value} value={brand.value}>
          {brand.label}
        </MenuItem>
      ))}
    </Select>
  );
};
