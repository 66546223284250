import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)({
  textTransform: 'none',
  backgroundColor: '#2596be',
  color: 'white',
  padding: '0.25rem 1rem',
  borderRadius: '0.5rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  height: '2.7rem',
  marginRight: '0.5rem',
  '&:hover': {
    backgroundColor: '#1e7ba6',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  },
});

export const StyledAppBar = styled(AppBar)({
  height: '4.9rem',
  marginBottom: '0.5rem',
  backgroundColor: '#2596be',
});

export const StyledLogoBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
  },
});

export const StyledTypography = styled(Typography)({
  color: '#fff',
  fontSize: '1.25rem',
  fontWeight: 'bold',
});
