import { useMemo } from 'react';
import bilkaLogo from '../media/logos/bilka_logo.svg';
import carlsJrLogo from '../media/logos/carls_jr_logo.svg';
import fotexLogo from '../media/logos/fotex_logo.svg';
import nettoLogo from '../media/logos/netto_logo.svg';
import sallingLogo from '../media/logos/salling_logo.svg';
import { BrandKey } from '../types/results/ICustomerDetailsResult';

enum brandsEnum {
  Bilka = 'bilka',
  BR = 'br',
  Foetex = 'foetex',
}

export const brandLogoMap: Record<BrandKey, string> = {
  bilka: bilkaLogo,
  carlsjr: carlsJrLogo,
  foetex: fotexLogo,
  nettode: nettoLogo,
  nettodk: nettoLogo,
  nettopl: nettoLogo,
  salling: sallingLogo,
  '': '',
};

const brandsRecord: Record<brandsEnum, string[]> = {
  [brandsEnum.Bilka]: ['Bilka Plus', 'Bilka.dk', 'BilkaToGo'],
  [brandsEnum.BR]: ['BR.dk'],
  [brandsEnum.Foetex]: [
    'føtex Hjem',
    'føtex Hjem SKI',
    'føtex Plus',
    'føtex.dk',
  ],
};

export const filterToBrandKey = (searchValue: string) => {
  for (const [key, value] of Object.entries(brandsRecord)) {
    if (value.includes(searchValue)) {
      return key;
    }
  }
  return brandsEnum.Bilka;
};

export const useFilteredBrandKey = () => {
  return useMemo(() => filterToBrandKey, []);
};

export const getBrandsEnumKeys = () => {
  return brandsEnum;
};

export const getBrandLogo = (brand: BrandKey): string => {
  const logo = brandLogoMap[brand];
  if (!logo) {
    console.error(`${brand} is not a valid brand`);
    return '';
  }
  return logo;
};

export type brandToProgramType = {
  [key in BrandKey]: {
    brandLogo: string;
    brandName: string;
    membershipNr: string;
    signupDate: string;
  };
};

export const brandToProgramMapping: brandToProgramType = {
  bilka: {
    brandLogo: bilkaLogo,
    brandName: 'Bilka',
    membershipNr: 'cpblkMembershipNr',
    signupDate: 'cpblkSignupDate',
  },
  carlsjr: {
    brandLogo: carlsJrLogo,
    brandName: "Carl's JR",
    membershipNr: 'cpcjrMembershipNr',
    signupDate: 'cpcjrSignupDate',
  },
  foetex: {
    brandLogo: fotexLogo,
    brandName: 'Føtex',
    membershipNr: 'cpfotMembershipNr',
    signupDate: 'cpfotSignupDate',
  },
  nettode: {
    brandLogo: nettoLogo,
    brandName: 'Netto DE',
    membershipNr: 'cpndeMembershipNr',
    signupDate: 'cpndeSignupDate',
  },
  nettodk: {
    brandLogo: nettoLogo,
    brandName: 'Netto DK',
    membershipNr: 'cpndkMembershipNr',
    signupDate: 'cpndkSignupDate',
  },
  nettopl: {
    brandLogo: nettoLogo,
    brandName: 'Netto PL',
    membershipNr: 'cpnplMembershipNr',
    signupDate: 'cpnplSignupDate',
  },
  salling: {
    brandLogo: sallingLogo,
    brandName: 'Salling',
    membershipNr: 'cpsalMembershipNr',
    signupDate: 'cpsalSignupDate',
  },
  '': {
    brandLogo: '',
    brandName: '',
    membershipNr: '',
    signupDate: '',
  },
};
