import { IReceiptSummaryResult, ReceiptType } from '../types/results/IReceipt';
import { formatDateToMonthYear } from './date-utils/formatDateToMonthYear';

// Helper function to get total discount in a month
export const sumMemberDiscounts = (
  receipts: IReceiptSummaryResult[],
): number => {
  return receipts.reduce((sum, receipt) => sum + receipt.memberDiscount, 0);
};

export const groupReceiptsByMonth = (
  receipts: IReceiptSummaryResult[],
): Map<string, IReceiptSummaryResult[]> => {
  return receipts.reduce((acc, receipt) => {
    const monthYear = formatDateToMonthYear(receipt.date);
    const group = acc.get(monthYear) || [];
    acc.set(monthYear, [...group, receipt]);
    return acc;
  }, new Map<string, IReceiptSummaryResult[]>());
};

// Helper function to get localized receipt type
export const getLocalizedReceiptType = (
  receiptType: ReceiptType,
  t: (key: string) => string,
): string => {
  const receiptTypeToLocalized: Record<ReceiptType, string> = {
    LIGHT: t('receipt.light'),
    FULL: t('receipt.full'),
    MERGED: t('receipt.merged'),
  };
  return receiptTypeToLocalized[receiptType];
};
