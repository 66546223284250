import React from 'react';
import {
  BrandKey,
  IAccountData,
} from '../../types/results/ICustomerDetailsResult';
import DetailItem from '../mui-helpers/DetailItem';
import { emptyValueReplacement } from '../../utils/emptyValueReplacement';
import { useTranslation } from 'react-i18next';
import {
  StyledDescription,
  StyledHeader,
} from '../mui-helpers/customComponentStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import { brandToProgramMapping } from '../../utils/brands-utils';
import { appConfig } from '../../appConfig';
import { CardMedia, ListItemButton } from '@mui/material';
import { formatDate } from '../../utils/date-utils/formatDate';

interface CustomerProgramProps {
  accountData: IAccountData;
}

type CustomerProgramType = {
  [key: string]: string | null;
};

type BrandDetailsType = {
  brandLogo: string;
  brandName: string;
  membershipNr: string | null;
  signupDate: string | null;
};

/**
 * Function to get brand details for a specific brand
 * @param brandKey - The key for the brand in the mapping (e.g. 'bilka', 'carlsjr')
 * @param customerProgram - The customer program data containing membership numbers and signup dates
 * @returns An object with brandLogo, brandName, membershipNr, and signupDate (or null if not found)
 */
const getBrandDetails = (
  brandKey: BrandKey,
  customerProgram: CustomerProgramType,
) => {
  const brandMapping = brandToProgramMapping[brandKey];
  if (!brandMapping) {
    return null;
  }
  const membershipNrKey = brandMapping.membershipNr;
  const signupDateKey = brandMapping.signupDate;
  const membershipNr = customerProgram[membershipNrKey] || null;
  const signupDate = customerProgram[signupDateKey] || null;
  return {
    brandLogo: brandMapping.brandLogo,
    brandName: brandMapping.brandName,
    membershipNr,
    signupDate,
  };
};

const ProgramMemberships: React.FC<CustomerProgramProps> = ({
  accountData,
}) => {
  const { customerProgram } = accountData;
  const { t } = useTranslation();
  const customerBrandDetails = appConfig.brands.reduce<BrandDetailsType[]>(
    (acc, brand) => {
      if (!customerProgram) {
        return acc;
      }
      const brandDetail = getBrandDetails(brand.value, customerProgram);
      if (brandDetail?.membershipNr) {
        acc.push(brandDetail);
      }
      return acc;
    },
    [],
  );

  const renderNoPrograms = () => {
    if (customerProgram === null || customerBrandDetails.length === 0) {
      return (
        <StyledDescription sx={{ padding: '1rem' }}>
          {t('customerProgram.noPrograms')}
        </StyledDescription>
      );
    }
    return null;
  };

  const renderProgramMemberships = () => (
    <Grid container paddingX={4}>
      {customerBrandDetails.map((detail, index) => (
        <Grid item xs={12} sm={5} md={4} key={`${detail.brandName}-${index}`}>
          <List style={{ display: 'flex' }}>
            <ListItemButton
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: '0',
              }}
            >
              <CardMedia
                component="img"
                image={detail?.brandLogo}
                height={40}
                style={{
                  objectFit: 'contain',
                  width: 'auto',
                  marginBottom: '0.4rem',
                }}
              />
              <DetailItem
                label={t('customerProgram.membershipNrLabel')}
                value={emptyValueReplacement(
                  detail?.membershipNr,
                  t,
                  t('customerProgram.notEnrolled'),
                )}
              />
              <DetailItem
                label={t('customerProgram.signupDateLabel')}
                value={
                  detail?.signupDate
                    ? formatDate(detail.signupDate)
                    : t('customerProgram.noDate')
                }
              />
            </ListItemButton>
          </List>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Accordion sx={{ my: 2 }} defaultExpanded component={Paper} elevation={3}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="program-memberships"
      >
        <StyledHeader gutterBottom>{t('customerProgram.title')}</StyledHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {renderProgramMemberships()}
          {renderNoPrograms()}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProgramMemberships;
