import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CustomerDetails from './CustomerDetails';
import { useSectionContext } from '../../SectionContext';
import OrderDetailsHeader from './order-details-bar';
import ProductTable from './collapse/product-details/ProductTable';
import CustomTabPanel from '../mui-helpers/CustomTabPanel';
import { IOrderDetailsResult } from '../../types/results/IOrderDetailsResult';
import { useSnackbar } from '../SnackbarContextType';
import { useParams } from 'react-router-dom';
import { OrderService } from '../../service/OrderService';
import { IGetOrderDetailsParams } from '../../types/payloads';
import { addressFormatter } from '../../utils/addressFormatter';
import ShippingAndBilling from './collapse/product-details/ShippingAndBilling';
import ScrollableAccordion from '../mui-helpers/ScrollableAccordion';
import Grid from '@mui/material/Grid';
import CustomerRemarks from './customer-remarks-tab/CustomerRemarks';
import NShift from './nShift-tab';
import { useCustomerContext } from '../../CustomerContext';
import { trackPromise } from 'react-promise-tracker';
import NoContentMessage from '../mui-helpers/NoContentMessage';
import Loader from '../mui-helpers/Loader';
import { TextConstants } from '../../utils/TextConstant';
import { ICustomTab } from '../mui-helpers/ICustomTab';
import { emptyValueReplacement } from '../../utils/emptyValueReplacement';

const OrderDetailsTab: React.FC = () => {
  const { setSection } = useSectionContext();
  const { setCustomerAddress, customerDetails } = useCustomerContext();
  const [selectedOrderDetails, setSelectedOrderDetails] =
    useState<IOrderDetailsResult>();
  const { showSnackbar } = useSnackbar();
  let { orderId, brand } = useParams();
  const orderService: OrderService = new OrderService();

  useEffect(() => {
    const getOrderDetails = async () => {
      try {
        const orderDetails = await orderService.getFullOrderDetails({
          orderId: orderId,
          brand: brand,
        } as IGetOrderDetailsParams);

        if (orderDetails) {
          setCustomerAddress(addressFormatter(orderDetails.shippingAddress));
        }
        setSelectedOrderDetails(orderDetails);
      } catch (err) {
        showSnackbar(`Ingen detaljer fundet for ordre-id: ${orderId}`);
        console.error('Error fetching order details', err);
        window.close();
      }
    };
    trackPromise(getOrderDetails(), 'order-details-area');
    setSection('OrderSearch');
  }, [orderId, brand]); // eslint-disable-line react-hooks/exhaustive-deps

  const triggerOrderDetailsRender = (remark: string) => {
    const detailsWithoutReference = JSON.parse(
      JSON.stringify(selectedOrderDetails),
    );
    detailsWithoutReference.customerRemarks.push(remark);
    setSelectedOrderDetails(detailsWithoutReference);
  };

  if (!selectedOrderDetails) {
    return <NoContentMessage />;
  }

  const tabs: ICustomTab[] = [
    {
      id: 'productOverviewTab',
      name: TextConstants.ORDER_DETAILS_TAB_NAMES[0],
      content: (
        <Box key="productOverview" p={3}>
          <OrderDetailsHeader order={selectedOrderDetails} />
          {selectedOrderDetails.products.length > 0 && (
            <ProductTable products={selectedOrderDetails.products} />
          )}
          <Grid sx={{ mt: 3 }} container spacing={5}>
            <Grid item xs={12} md={6}>
              <ShippingAndBilling
                billingAddress={selectedOrderDetails.billingAddress}
                shippingAddress={selectedOrderDetails.shippingAddress}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ScrollableAccordion
                title={`Text/Note (${selectedOrderDetails.customerRemarks.length})`}
                defaultExpanded={true}
              >
                <CustomerRemarks
                  orderDetails={selectedOrderDetails}
                  brand={brand}
                  callBack={triggerOrderDetailsRender}
                />
              </ScrollableAccordion>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      id: 'productTrackingTab',
      name: TextConstants.ORDER_DETAILS_TAB_NAMES[1],
      content: (
        <Box key="ProductTracking" p={3}>
          <OrderDetailsHeader order={selectedOrderDetails} />
          <NShift
            trackingId={
              selectedOrderDetails.products[0]?.tracking.trackingIds[0]
            }
            invoiceDate={selectedOrderDetails.products[0]?.invoiceDate}
          />
        </Box>
      ),
    },
    {
      id: 'customerDetailsTab',
      name: TextConstants.ORDER_DETAILS_TAB_NAMES[2],
      content: (
        <Box key="CustomerDetails" p={3}>
          {customerDetails && (
            <CustomerDetails
              customerEmail={
                emptyValueReplacement(customerDetails.email) as string
              }
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Loader area="order-details-area" />
      <CustomTabPanel tabs={tabs} />
    </Box>
  );
};

export default OrderDetailsTab;
