import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IProduct } from '../../../types/results/IOrderDetailsResult';
import { IShipmentPackage } from '../../../types/results/InShiftShipmentsResult';

type collapseContentType = IProduct | IShipmentPackage;

interface ICollapse {
  collapseContent: collapseContentType;
  setCollapseContent: Dispatch<SetStateAction<collapseContentType>>;
}

const CollapseContext = createContext<{
  collapseContent: collapseContentType;
  setCollapseContent: Dispatch<SetStateAction<collapseContentType>>;
}>({} as ICollapse);

const CollapseProvider = (props: { children: React.ReactNode }) => {
  const [collapseContent, setCollapseContent] = useState<collapseContentType>(
    {} as collapseContentType | (() => collapseContentType),
  );
  const value = useMemo(
    () => ({ collapseContent, setCollapseContent }),
    [collapseContent, setCollapseContent],
  );

  useEffect(() => {}, [collapseContent]);

  return (
    <CollapseContext.Provider value={value}>
      {props.children}
    </CollapseContext.Provider>
  );
};

export { CollapseContext, CollapseProvider };
