import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { SearchOutlined } from '@mui/icons-material';
import { BrandSelect } from '../BrandSelect';
import { useTranslation } from 'react-i18next';
import {
  checkInputTypeForLoyalty,
  sanitizeInput,
  SearchType,
  validateEmail,
} from '../../utils/checkInputType';
import { BrandKey } from '../../types/results/ICustomerDetailsResult';
import { useSectionContext } from '../../SectionContext';
import styled from '@mui/system/styled';

interface SearchBarProps {
  manualInput: string;
  setManualInput: (input: string) => void;
  handleFetchCustomerDetails: (
    inputQuery: string,
    brand: BrandKey,
    searchType: SearchType,
  ) => void;
  errorMessage: string | undefined;
  selectedBrand: BrandKey;
  setSelectedBrand: (brand: BrandKey) => void;
}

const StyledTextField = styled(TextField)(() => ({
  width: '50%',
  color: 'white',
  '& .MuiInputBase-input': { color: 'white' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
}));

const SearchBar: React.FC<SearchBarProps> = ({
  manualInput,
  setManualInput,
  handleFetchCustomerDetails,
  errorMessage,
  selectedBrand,
  setSelectedBrand,
}) => {
  const [validationError, setValidationError] = useState<string | null>(null);
  const [searchType, setSearchType] = useState<SearchType>(SearchType.EMAIL);
  const { t } = useTranslation();
  const { section } = useSectionContext();

  const handleSanitizationAndValidation = (input: string): string | null => {
    const sanitizedInput = sanitizeInput(input);
    if (!sanitizedInput) {
      return t('messages.inputRequired');
    }

    if (checkInputTypeForLoyalty(sanitizedInput) === 'memberId') {
      setSearchType(SearchType.MEMBER_ID);
    } else {
      setSearchType(SearchType.EMAIL);
      if (!validateEmail(sanitizedInput)) {
        return t('messages.invalidEmail');
      }
    }

    return null;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    const error = handleSanitizationAndValidation(input);
    setValidationError(error);
    setManualInput(input);
  };

  const handleSearchClick = () => {
    const sanitizedInput = sanitizeInput(manualInput);
    const error = handleSanitizationAndValidation(sanitizedInput);
    if (error) {
      setValidationError(error);
    } else {
      handleFetchCustomerDetails(sanitizedInput, selectedBrand, searchType);
    }
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  return (
    <StyledTextField
      id="search-bar"
      value={manualInput}
      onChange={handleInputChange}
      onKeyDown={handleKeyPress}
      label={
        section === 'OrderSearch'
          ? t('messages.searchByEmailOrOrderID')
          : t('messages.searchByEmailOrMemberId')
      }
      variant="outlined"
      size="small"
      InputLabelProps={{ style: { color: 'white' } }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <BrandSelect
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            aria-label="search"
            onClick={handleSearchClick}
            edge="end"
            color="secondary"
            sx={{ color: 'white' }}
          >
            <SearchOutlined />
          </IconButton>
        ),
      }}
      helperText={validationError || errorMessage || ''}
      error={!!validationError || !!errorMessage}
    />
  );
};

export default SearchBar;
