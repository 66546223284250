import { RetryRequestNames } from '../../../types/RetryRequestNames';
import {
  IWalletResult,
  IWalletTransactionResult,
} from '../../../types/results/IWalletResult';
import { ICreditCardDetail } from '../cards/ICreditCardDetail';
import { OffersData } from '../../../types/results/IOffersResults';
import { IPunchCardResponse } from '../../../types/results/IPunchCards';
import { IReceiptSummaryResult } from '../../../types/results/IReceipt';

type RetryFunctions = {
  [key in RetryRequestNames]?: () => Promise<{
    data: any;
    failed: boolean;
    errorCode?: number;
    requestName?: RetryRequestNames;
    attempts: number;
  }>;
};

const initialState = {
  creditCard: null as ICreditCardDetail[] | null,
  walletDetails: null as IWalletResult | null,
  walletTransactions: null as IWalletTransactionResult | null,
  offers: null as OffersData | null,
  punchCards: null as IPunchCardResponse | null,
  receipts: null as IReceiptSummaryResult[] | null,
  failedRequests: [] as (string | null | undefined)[],
  retryFunctions: {} as RetryFunctions,
};

type State = typeof initialState;

type Action =
  | { type: 'SET_CREDIT_CARD'; payload: ICreditCardDetail[] | null }
  | { type: 'SET_WALLET_DETAILS'; payload: IWalletResult | null }
  | {
      type: 'SET_WALLET_TRANSACTIONS';
      payload: IWalletTransactionResult | null;
    }
  | { type: 'SET_OFFERS'; payload: OffersData | null }
  | { type: 'SET_PUNCH_CARDS'; payload: IPunchCardResponse | null }
  | { type: 'SET_RECEIPTS'; payload: IReceiptSummaryResult[] | null }
  | { type: 'SET_FAILED_REQUESTS'; payload: (string | null | undefined)[] }
  | { type: 'SET_RETRY_FUNCTIONS'; payload: RetryFunctions };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CREDIT_CARD':
      return { ...state, creditCard: action.payload };
    case 'SET_WALLET_DETAILS':
      return { ...state, walletDetails: action.payload };
    case 'SET_WALLET_TRANSACTIONS':
      return { ...state, walletTransactions: action.payload };
    case 'SET_OFFERS':
      return { ...state, offers: action.payload };
    case 'SET_PUNCH_CARDS':
      return { ...state, punchCards: action.payload };
    case 'SET_RECEIPTS':
      return { ...state, receipts: action.payload };
    case 'SET_FAILED_REQUESTS':
      return { ...state, failedRequests: action.payload };
    case 'SET_RETRY_FUNCTIONS':
      return { ...state, retryFunctions: action.payload };
    default:
      return state;
  }
};

export { initialState, reducer };
export type { Action };
