import React, { useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomerDetails from '../order-details-view/CustomerDetails';
import { useSectionContext } from '../../SectionContext';
import { useCustomerContext } from '../../CustomerContext';
import CustomTabPanel from '../mui-helpers/CustomTabPanel';
import Loader from '../mui-helpers/Loader';
import Receipt from './receipt/Receipt';
import WalletDetailsHeader from './wallet/WalletDetailsHeader';
import WalletTransaction from './wallet/WalletTransactions';
import PunchCardList from './trade-drivers/PunchCardList';
import { fetchAllCustomerData } from '../../service/CustomerService';
import Offers from './offer/Offers';
import { useSnackbar } from '../SnackbarContextType';
import { useTranslation } from 'react-i18next';
import { ICustomTab } from '../mui-helpers/ICustomTab';
import { RetryRequestNames } from '../../types/RetryRequestNames';
import ReplayIcon from '@mui/icons-material/Replay';
import { handleManualRetry } from '../../service/request-helpers/loyalty/handleManualRetry';
import { initialState, reducer } from './reducers/loyaltyReducer';

const Loyalty = () => {
  const { customerBrand, customerDetails, profileDetails } =
    useCustomerContext();
  const { section, setSection } = useSectionContext();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllCustomerData = async () => {
    setSection('Loyalty');
    if (customerDetails?.uid && profileDetails?.trackingId && customerBrand) {
      try {
        const {
          cardDetails,
          walletDetails,
          walletTransactions,
          memberOffers,
          punchCards,
          receipts,
          failedRequests,
          retryFunctions,
        } = await fetchAllCustomerData(
          customerDetails.uid,
          profileDetails.trackingId,
          customerBrand,
        );

        dispatch({
          type: 'SET_CREDIT_CARD',
          payload: cardDetails?.data || null,
        });
        dispatch({
          type: 'SET_WALLET_DETAILS',
          payload: walletDetails?.data || null,
        });
        dispatch({
          type: 'SET_WALLET_TRANSACTIONS',
          payload: walletTransactions?.data || null,
        });
        dispatch({ type: 'SET_OFFERS', payload: memberOffers?.data || null });
        dispatch({
          type: 'SET_PUNCH_CARDS',
          payload: punchCards?.data || null,
        });
        dispatch({ type: 'SET_RECEIPTS', payload: receipts?.data || null });
        dispatch({ type: 'SET_FAILED_REQUESTS', payload: failedRequests });
        dispatch({ type: 'SET_RETRY_FUNCTIONS', payload: retryFunctions });
      } catch (error: unknown) {
        console.error('Failed to fetch customer data:', error);
        showSnackbar(t('errors.customerContextSearch'));
      }
    }
  };

  useEffect(() => {
    getAllCustomerData();
    return () => setSection('OrderSearch');
    // Reasonable to ignore because we don't want to include the setter of the context in the dependency array
  }, [profileDetails, customerDetails, customerBrand]); // eslint-disable-line react-hooks/exhaustive-deps

  if (section !== 'Loyalty') {
    return <Loader loadingMessage={t('messages.loading')} />;
  }

  const renderRetryButton = (requestName: RetryRequestNames) => {
    if (!state.failedRequests.includes(requestName)) return null;

    return (
      <Button
        onClick={() => handleManualRetry(requestName, state, dispatch)}
        size="small"
        startIcon={<ReplayIcon />}
        color="primary"
      >
        {t('generic.retry')}
      </Button>
    );
  };

  const createTab = (
    id: string,
    name: string,
    content: React.ReactNode,
    requestName: RetryRequestNames,
    noDataTooltip?: string,
    disabled: boolean = false,
  ): ICustomTab => {
    const retryButton = renderRetryButton(requestName);
    return {
      id,
      name: (
        <Box display="flex" alignItems="center">
          {t(name)}
          {retryButton}
        </Box>
      ),
      content: (
        <Box key={id} p={3}>
          {content}
        </Box>
      ),
      disabled,
      retryButton: retryButton,
      noDataTooltip,
    };
  };

  const tabConfig = [
    {
      id: 'customerProfileTab',
      name: 'loyaltyTabs.customerProfile',
      content: (
        <CustomerDetails
          customerEmail={customerDetails?.email ? customerDetails.email : ''}
          creditCard={state.creditCard}
        />
      ),
      requestName: RetryRequestNames.CardDetails,
    },
    {
      id: 'walletTab',
      name: 'loyaltyTabs.wallet',
      content: (
        <>
          <WalletDetailsHeader wallet={state.walletDetails} />
          <WalletTransaction
            transactions={state.walletTransactions?.data || []}
          />
        </>
      ),
      requestName: RetryRequestNames.WalletDetails,
      noDataTooltip: t('customTabPanel.noWallet'),
      disabled: !state.walletDetails,
    },
    {
      id: 'memberOffersTab',
      name: 'loyaltyTabs.memberOffers',
      content: <Offers initialOffers={state.offers?.memberOffers} />,
      requestName: RetryRequestNames.MemberOffers,
      noDataTooltip: t('customTabPanel.noOffers'),
      disabled:
        !Array.isArray(state.offers?.memberOffers) ||
        state.offers?.memberOffers.length === 0,
    },
    {
      id: 'punchCardsTab',
      name: 'loyaltyTabs.punchCards',
      content: (
        <PunchCardList
          initialPunchCards={state.punchCards?.memberPunchCards || []}
        />
      ),
      requestName: RetryRequestNames.PunchCards,
      noDataTooltip: t('customTabPanel.noPunchCards'),
      disabled:
        !Array.isArray(state.punchCards?.memberPunchCards) ||
        state.punchCards?.memberPunchCards.length === 0,
    },
    {
      id: 'receiptsTab',
      name: 'loyaltyTabs.receipts',
      content: <Receipt receipts={state.receipts || []} />,
      requestName: RetryRequestNames.Receipts,
      noDataTooltip: t('customTabPanel.noReceipts'),
      disabled: !Array.isArray(state.receipts) || state.receipts.length === 0,
    },
  ];

  const tabs: ICustomTab[] = tabConfig.map(
    ({ id, name, content, requestName, noDataTooltip, disabled }) =>
      createTab(id, name, content, requestName, noDataTooltip, disabled),
  );

  return (
    <Box sx={{ width: '100%' }}>
      <CustomTabPanel tabs={tabs} />
    </Box>
  );
};

export default Loyalty;
