import { IBrand } from './types/results/ICustomerDetailsResult';

export const appConfig = {
  nodeEnv: process.env.NODE_ENV || 'development',
  sgApiBaseUrlV1:
    process.env.REACT_APP_SG_API_BASE_URL_V1 ||
    'https://api.sallinggroup.com/v1-test/',
  sgApiBaseUrlV2:
    process.env.REACT_APP_SG_API_BASE_URL_V2 ||
    'https://api.sallinggroup.com/v2-test/',
  sgApiNextOrdersUrlV1:
    process.env.REACT_APP_SG_API_NEXT_ORDERS_URL_V1 ||
    'https://api.sallinggroup.com/v1-test/customer-service/next/',
  sgApiNextOrdersUrlV2:
    process.env.REACT_APP_SG_API_NEXT_ORDERS_URL_V2 ||
    'https://api.sallinggroup.com/v2-test/customer-service/next/',
  sgApiNshiftShipments:
    process.env.REACT_APP_SG_API_NSHIFT_SHIPMENTS ||
    'https://api.sallinggroup.com/v1-test/customer-service/next/shipments/',
  sgApiStoreUrl:
    process.env.REACT_APP_SG_API_STORE_URL ||
    'https://api.sallinggroup.com/v2/stores',
  sgImgStoreUrl:
    process.env.REACT_APP_SG_IMG_STORE_URL ||
    'https://res.cloudinary.com/sallingtest/image/upload/',
  sgBearer:
    process.env.REACT_APP_SG_BEARER || 'b64757e6-d177-4318-97aa-2ccfd12ca642',
  zendeskCustomFieldOrderCallerId:
    process.env.REACT_APP_ZENDESK_CUSTOM_FIELD_ORDER_CALLER_ID ||
    '360000365385',
  zendeskCustomFieldOrderNumber:
    process.env.REACT_APP_ZENDESK_CUSTOM_FIELD_ORDER_NUMBER ||
    '360000360989',
  adClientId:
    process.env.REACT_APP_AD_CLIENT_ID || '0126be4d-187c-400b-9e52-8de6a2cef5c1',
  adTenantId:
    process.env.REACT_APP_AD_TENANT_ID || 'a9d3abc9-3302-4217-8969-726e46809c6d',
  sgApimIdentifierId:
    process.env.REACT_APP_SG_APIM_IDENTIFIER_ID ??
    'G7DCZA1R9J2P6S4DQP478K65A0',
  allowedADLoyaltyRoles:
    process.env.REACT_APP_ALLOWED_AD_LOYALTY_ROLES ||
    'f40212d4-8524-49e8-8703-95d7516c2277,6882ffe2-21dd-4b8c-bec5-e2d6f648a75b',
  allowedADRoles:
    process.env.REACT_APP_ALLOWED_AD_ROLES ||
    'f40212d4-8524-49e8-8703-95d7516c2277,18c2fbb9-8469-4c28-a885-5cdc7142a506,d9bf9630-091d-4385-bc0f-63d7f5f2faad,49349e2b-86ef-4009-b5ee-720d0171eb0e,16d57df2-25d3-459e-a2d5-d91789b47db0',
  brands: [
    { value: 'bilka', label: 'Bilka' },
    { value: 'carlsjr', label: "Carl's Jr." },
    { value: 'foetex', label: 'Føtex' },
    { value: 'nettode', label: 'Netto DE' },
    { value: 'nettodk', label: 'Netto DK' },
    { value: 'nettopl', label: 'Netto PL' },
    { value: 'salling', label: 'Salling' },
  ] as IBrand[],
  orderSearchBrands: [
    { value: 'br', label: 'BR' },
    { value: 'bilka', label: 'Bilka' },
    { value: 'foetex', label: 'Føtex' },
  ] as IBrand[],
  membershipOffersBurnableBrands: ['carlsjr'],
};
