import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { currencyFormatter } from '../../../utils/priceFormatter';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';
import { IWalletResult } from '../../../types/results/IWalletResult';
import { StyledColoredCard } from '../../mui-helpers/customComponentStyles';
import Tooltip from '@mui/material/Tooltip';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { useTranslation } from 'react-i18next';

interface WalletDetailsHeaderProps {
  wallet: IWalletResult | null;
}

const WalletDetailsHeader: React.FC<WalletDetailsHeaderProps> = ({
  wallet,
}) => {
  const { t } = useTranslation();
  const fields = useMemo(() => {
    if (!wallet) return [];
    return [
      {
        label: t('walletHeader.trackingId'),
        value: emptyValueReplacement(wallet.trackingId),
      },
      {
        label: t('walletHeader.balance'),
        value: currencyFormatter(wallet.balance, wallet.currency),
      },
      {
        label: t('walletHeader.currency'),
        value: emptyValueReplacement(wallet.currency),
      },
    ];
  }, [wallet, t]);

  if (!wallet) {
    return <NoContentMessage infoMessage={t('walletHeader.noData')} />;
  }
  return (
    <Box sx={{ px: 3 }}>
      <StyledColoredCard>
        <CardContent>
          <Grid container>
            {fields.map((field, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                lg={1}
                key={`${index}-${field.label}`}
              >
                <Typography variant="caption" gutterBottom>
                  {field.label}
                </Typography>
                {field.label === t('walletHeader.trackingId') ? (
                  <Tooltip title={field.value}>
                    <Typography
                      gutterBottom
                      onClick={() => copyToClipboard(field.value.toString())}
                      sx={{
                        cursor: 'pointer',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '75%',
                        flex: 'shrink',
                      }}
                    >
                      {field.value}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography gutterBottom>{field.value}</Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </StyledColoredCard>
    </Box>
  );
};

export default WalletDetailsHeader;
