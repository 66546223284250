import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { IShipment } from '../../../types/results/InShiftShipmentsResult';
import { NShiftService } from '../../../service/NShiftService';
import { useSnackbar } from '../../SnackbarContextType';
import utc from 'dayjs/plugin/utc';
import Loader from '../../mui-helpers/Loader';
import ShipmentSteps from './ShipmentSteps';
import timezone from 'dayjs/plugin/timezone';
import DateRangePicker from '../../mui-helpers/DateRangePicker';
import locale from 'dayjs/locale/da';
import ShipmentDetails from './ShipmentDetails';
import Box from '@mui/material/Box';
import { trackPromise } from 'react-promise-tracker';
import NoContentMessage from '../../mui-helpers/NoContentMessage';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(locale);

const currentDate = dayjs();

interface NShiftProps {
  trackingId: string | null;
  invoiceDate: string | null;
}

const NShift: React.FC<NShiftProps> = ({ trackingId, invoiceDate }) => {
  const nShiftService: NShiftService = new NShiftService();
  const { showSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    invoiceDate ? dayjs(invoiceDate) : currentDate,
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    startDate ? startDate.add(30, 'day') : null,
  );
  const [shipments, setShipments] = useState<IShipment[] | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    if (!trackingId) {
      showSnackbar(
        'Der opstod en fejl under forsøg på at udtrække tracking-id et. Automatisk søgning er ikke mulig.',
      );
    } else {
      handleSearch();
    }
  }, [trackingId, invoiceDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateChange = (
    startDate: dayjs.Dayjs | null,
    endDate: dayjs.Dayjs | null,
  ) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getConsignmentShipments = async (
    consignmentIdWithVendor: string,
    startDate: string | null,
    endDate: string | null,
  ) => {
    try {
      const shipmentsResult = await nShiftService.getNShiftShipments({
        query: consignmentIdWithVendor,
        startDate: startDate,
        endDate: endDate,
      });
      setShipments(shipmentsResult);
    } catch (err) {
      showSnackbar(
        `Ingen forsendelser fundet for consignment id: ${consignmentIdWithVendor}`,
      );
      console.error('Something went wrong: ', err);
    }
  };

  const handleSearch = async () => {
    // Check if trackingId is available, if not, ask user to enter manually
    if (!searchTerm) {
      showSnackbar('Indtast forsendelses-id');
      return;
    }

    if (startDate && endDate) {
      const diffInDays = endDate.diff(startDate, 'day');
      if (diffInDays > 30) {
        showSnackbar(
          'Det valgte interval overstiger 30 dage. Juster venligst datoerne.',
        );
        return;
      }
    }

    try {
      const start = startDate ? startDate.startOf('day').toISOString() : null;
      const end = endDate ? endDate.endOf('day').toISOString() : null;
      await trackPromise(
        getConsignmentShipments(searchTerm, start, end),
        'nshift-area',
      );
    } catch (error) {
      console.error('Error fetching shipments:', error);
      showSnackbar('Fejl opstod under søgning efter forsendelser.');
    }
  };

  const generateShipmentStepsAndDetails = () => {
    if (shipments) {
      return shipments.map((shipment, index) => (
        <Grid
          container
          spacing={2}
          marginTop={4}
          key={`${index + '-' + shipment.uuid}`}
        >
          <Grid item xs={12}>
            <ShipmentSteps shipment={shipment} />
          </Grid>
          <Grid item xs={12}>
            <ShipmentDetails shipment={shipment} />
          </Grid>
        </Grid>
      ));
    } else {
      return (
        <Grid item xs={12} mt={6}>
          <NoContentMessage />
        </Grid>
      );
    }
  };

  return (
    <Box component={Paper} elevation={3} sx={{ marginTop: '1rem' }}>
      <Loader area="nshift-area" />
      <Grid container spacing={4} p={3}>
        <Grid item xs={12} sm={8} md={6}>
          <DateRangePicker
            initialStartDate={currentDate}
            onDateChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <TextField
            fullWidth
            label="Søg efter forsendelser"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleSearch}
                  startIcon={<SearchIcon />}
                ></Button>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginY={2} p={3}>
        {generateShipmentStepsAndDetails()}
      </Grid>
    </Box>
  );
};

export default NShift;
