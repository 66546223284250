import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    'AIzaSyD9K-s5-N9tOqXRm4UEEwbNIYx3CR6VCDA'',
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    '',
  databaseURL:
    process.env.REACT_APP_FIREBASE_DATABASE_URL ||
    '',
  projectId:
    process.env.REACT_APP_FIREBASE_PROJECT_ID ||
    'zendesk-event-emitter-preprod',
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
    'zendesk-event-emitter-preprod.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
    '765526267089',
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ||
    '1:765526267089:web:ef1cfdfd2308c141f321f0',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
