import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { callMsGraph, Group } from './utils/graphUtils';
import { appConfig } from './appConfig';

export const AuthorizationHook = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const [isLoyaltyAuthorized, setIsLoyaltyAuthorized] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const checkRoles = async () => {
      if (!isAuthenticated || inProgress !== InteractionStatus.None) {
        return;
      }

      const account = instance.getActiveAccount();
      if (!account) {
        setIsAuthorized(false);
        setIsLoyaltyAuthorized(false);
        return;
      }

      try {
        const { accessToken } = await instance.acquireTokenSilent({
          scopes: ['AppRoleAssignment.ReadWrite.All'],
          account: account,
        });

        const roles = await callMsGraph(accessToken);
        const allowedRoles = appConfig.allowedADRoles.split(',');
        const allowedLoyaltyRoles = appConfig.allowedADLoyaltyRoles.split(',');
        const isUserAuthorized = roles.some((role: Group) =>
          allowedRoles.includes(role.appRoleId),
        );
        const isLoyaltyUserAuthorized = roles.some((role: Group) =>
          allowedLoyaltyRoles.includes(role.appRoleId),
        );

        setIsAuthorized(isUserAuthorized);
        setIsLoyaltyAuthorized(isLoyaltyUserAuthorized);
      } catch (error) {
        console.error(error);
        setIsAuthorized(false);
        setIsLoyaltyAuthorized(false);
      }
    };

    if (isAuthenticated) {
      checkRoles();
    }
  }, [isAuthenticated, inProgress, instance]);

  return { isAuthorized, isLoyaltyAuthorized };
};
