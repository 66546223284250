import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  BrandKey,
  IProfile,
  IUser,
} from './types/results/ICustomerDetailsResult';
import { IOrderResult } from './types/results/IOrderResult';

interface CustomerContextType {
  customerBrand: BrandKey;
  customerDetails: IUser | null;
  profileDetails: IProfile | null;
  customerAddress: string;
  customerEmail: string;
  customerOrders: IOrderResult[] | null;
  setCustomerBrand: Dispatch<SetStateAction<BrandKey>>;
  setCustomerDetails: Dispatch<SetStateAction<IUser | null>>;
  setProfileDetails: Dispatch<SetStateAction<IProfile | null>>;
  setCustomerAddress: Dispatch<SetStateAction<string>>;
  setCustomerEmail: Dispatch<SetStateAction<string>>;
  setCustomerOrders: Dispatch<SetStateAction<IOrderResult[] | null>>;
  resetStates: () => void;
}

const CustomerContext = createContext<CustomerContextType | undefined>(
  undefined,
);

const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error(
      'useCustomerContext must be used within a CustomerProvider',
    );
  }
  return context;
};

const CustomerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [customerBrand, setCustomerBrand] = useState<BrandKey>('');
  const [customerDetails, setCustomerDetails] = useState<IUser | null>(null);
  const [customerOrders, setCustomerOrders] = useState<IOrderResult[] | null>(
    null,
  );
  const [profileDetails, setProfileDetails] = useState<IProfile | null>(null);
  const [customerAddress, setCustomerAddress] = useState<string>('');
  const [customerEmail, setCustomerEmail] = useState<string>('');

  const resetStates = () => {
    setCustomerBrand('');
    setCustomerDetails(null);
    setProfileDetails(null);
  };

  const value = useMemo(
    () => ({
      customerBrand,
      setCustomerBrand,
      customerDetails,
      setCustomerDetails,
      profileDetails,
      setProfileDetails,
      customerAddress,
      setCustomerAddress,
      customerEmail,
      setCustomerEmail,
      customerOrders,
      setCustomerOrders,
      resetStates,
    }),
    [
      customerBrand,
      customerDetails,
      profileDetails,
      customerAddress,
      customerEmail,
      customerOrders,
    ],
  );

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};

export { useCustomerContext, CustomerProvider };
