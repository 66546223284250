import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Product } from '../../../types/results/IOffersResults';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';
import {
  EllipsisStyledTitle,
  StyledDescription,
} from '../../mui-helpers/customComponentStyles';
import DetailRows from './DetailsRows';
import productPlaceholder from '../../../media/placeholders/product_placeholder.png';
import CustomImage from '../../mui-helpers/CustomImage';
import InfoIconButton from '../../mui-helpers/InfoIconButton';
import { useTranslation } from 'react-i18next';
import FieldWithCopy from '../../mui-helpers/FieldWithCopy';
import { useCustomerContext } from '../../../CustomerContext';

const customImageStyles: React.CSSProperties = {
  width: '15rem',
  height: '20vh',
  maxHeight: '100%',
  objectFit: 'contain',
  objectPosition: 'center',
  marginTop: '1rem',
};

interface ProductListProps {
  products: Product[];
}

const getProductDetails = (product: Product, t: (key: string) => string) => {
  return [
    {
      label: t('offerDetails.unitPrice'),
      value: emptyValueReplacement(product.unitPriceText),
    },
    {
      label: t('offerDetails.discountPrice'),
      value: `${emptyValueReplacement(product.discountPrice)} ${emptyValueReplacement(product.currency)}`,
    },
    {
      label: t('offerDetails.originalPrice'),
      value: `${emptyValueReplacement(product.originalPrice)} ${emptyValueReplacement(product.currency)}`,
    },
    {
      label: t('offerDetails.discountAmount'),
      value: emptyValueReplacement(product.discountAmount),
    },
    {
      label: t('offerDetails.discountPercentage'),
      value: `${emptyValueReplacement(product.discountPercentage)}%`,
    },
    {
      label: t('offerDetails.hideSaving'),
      value: emptyValueReplacement(product.hideSaving, t),
    },
  ];
};

const ProductCard: React.FC<{ product: Product }> = ({ product }) => {
  const { t } = useTranslation();
  const { customerBrand } = useCustomerContext();

  if (customerBrand === 'salling') {
    return <></>;
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={product.id}>
      <Card
        sx={{ width: '100%', height: '100%', position: 'relative' }}
        elevation={4}
      >
        <InfoIconButton
          description={emptyValueReplacement(product.description) as string}
        />
        <Box display="flex" flexDirection="column" alignItems="center">
          <CustomImage
            imageId={product.imageId}
            title={product.title}
            height={240}
            fallbackImgUrl={productPlaceholder}
            styles={customImageStyles}
          />
          <CardContent sx={{ flexGrow: 1, overflow: 'auto' }}>
            <Tooltip title={product.title} arrow>
              <EllipsisStyledTitle>
                {emptyValueReplacement(product.title)}
              </EllipsisStyledTitle>
            </Tooltip>
            {emptyValueReplacement(product.subtitle) !==
              emptyValueReplacement(product.title) &&
              product.subtitle && (
                <Tooltip title={product.subtitle} arrow>
                  <StyledDescription
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textAlign: 'center',
                    }}
                  >
                    {`${t('offerDetails.subtitle')}: ${emptyValueReplacement(product.subtitle)}`}
                  </StyledDescription>
                </Tooltip>
              )}
            <FieldWithCopy
              value={product.id}
              label={t('productHeaders.articleId')}
            />
            <DetailRows details={getProductDetails(product, t)} />
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};

const ProductList: React.FC<ProductListProps> = ({ products }) => (
  <Box
    sx={{
      height: '75vh',
      overflow: 'auto',
    }}
  >
    <Grid container spacing={2} mt={0.1}>
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </Grid>
  </Box>
);

export default ProductList;
