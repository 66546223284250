import { Dispatch } from 'react';
import { RetryRequestNames } from '../../../types/RetryRequestNames';
import { Action } from '../../../components/loyalty/reducers/loyaltyReducer';

export const handleManualRetry = async (
  requestName: RetryRequestNames,
  state: any,
  dispatch: Dispatch<Action>,
) => {
  const retryFunction = state.retryFunctions[requestName];
  if (!retryFunction) return;

  try {
    const result = await retryFunction();
    if (!result.data) return;

    const updateState: Record<RetryRequestNames, Action['type']> = {
      [RetryRequestNames.CardDetails]: 'SET_CREDIT_CARD',
      [RetryRequestNames.WalletDetails]: 'SET_WALLET_DETAILS',
      [RetryRequestNames.WalletTransactions]: 'SET_WALLET_TRANSACTIONS',
      [RetryRequestNames.MemberOffers]: 'SET_OFFERS',
      [RetryRequestNames.PunchCards]: 'SET_PUNCH_CARDS',
      [RetryRequestNames.Receipts]: 'SET_RECEIPTS',
    };

    dispatch({ type: updateState[requestName], payload: result.data });
    dispatch({
      type: 'SET_FAILED_REQUESTS',
      payload: state.failedRequests.filter(
        (req: RetryRequestNames) => req !== requestName,
      ),
    });
  } catch (error) {
    console.error(`Failed to retry ${requestName}:`, error);
  }
};
